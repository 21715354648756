
import { instance } from '@/utils/http.js'

// 获取讲义类别
export const getCategory = function (params) {
  return instance({
    url: '/api/v1/lecture/get_source_name',
    method: 'get',
    params
  })
}

// 获取类别下的科目
export const getSubject = function (params) {
  return instance({
    url: '/api/v1/lecture/get_source_video_type',
    method: 'get',
    params
  })
}

// 根据科目，类别，获取公益讲座
export const getList = function (params) {
  return instance({
    url: '/api/v1/lecture/lecture_videos',
    method: 'get',
    params
  })
}

// 优质课程 -主页-公益讲座
export const getHomeList = function () {
  return instance({
    url: '/api/v1/home/lecture_video',
    method: 'get'
  })
}

//优质课程  知识点推荐位
export const getHomeLecture = function (params) {
  return instance({
    url: '/api/v1/home/point_teaching',
    method: 'get',
    params
  })
}